import React from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import LocalPageHeader from 'components/LocalPageHeader';

const MenuTemplateDetailHeader = ({menuTemplateLabel}) => {
	const navigate = useNavigate();
	return (
		<div>
			<LocalPageHeader
				headerTitle={menuTemplateLabel}
				capitalize={false}
				showBackButton
				backButtonAction={() => navigate('/sales-channel/menu-template', {state: {activeTabs: 'active'}})}
			/>
		</div>
	);
};

MenuTemplateDetailHeader.defaultProps = {
	menuTemplateLabel: null,
};

MenuTemplateDetailHeader.propTypes = {
	menuTemplateLabel: PropTypes.string,
};

export default MenuTemplateDetailHeader;
