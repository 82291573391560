import React, {useEffect, useState, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import './PromoForm.less';

import {Button, Card, Checkbox, DatePicker, Input, Modal, notification, Popconfirm, Radio, Select, TimePicker, Transfer, Typography} from 'antd';
import {DeleteOutlined, FileDoneOutlined, PercentageOutlined} from '@ant-design/icons';
import localization from 'localization';

import ProtectedComponent from 'components/ProtectedComponent';

import PromoTemplateModal from './PromoTemplateModal';
import MenuTemplateModal from './MenuTemplateModal';
import ApprovalIdModal from './ApprovalIdModal';
import ScheduleModal from './ScheduleModal';

import {createNewFlashSalePromo, createNewPromo, editPromo, getMenuDetailsFromMenuTemplate} from 'utils/request/promo';
import {getOutletList, getPlatformList} from 'utils/request/global';
import {handleErrorFetch, moneyFormat, promoRequestPayloadConverter, transferOutletFormatter} from 'utils/utils';
import {PROMO_TYPE, USER_ROLES} from 'utils/constants';

import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import {Transition} from 'react-transition-group';

const {Title, Text} = Typography;
const {Option} = Select;
const {confirm} = Modal;

const discountTypeLocale = localization.Promo.CreateNewPromo.ChoosePromoType;
const locale = localization.Promo.CreateNewPromo.Form;

const activeHours = [
	{
		label: locale.promoActiveAllDay,
		value: 'allDay',
	},
	{
		label: locale.promoActiveSpecificTime,
		value: 'specificTime',
	},
];

const maxUsageConfig = [
	{
		label: 'No Limit',
		value: 'unlimited',
	},
	{
		label: 'Specific Number',
		value: 'limited',
	},
];

const activeDay = [
	{
		label: locale.promoActiveEveryDay,
		value: 'everyDay',
	},
	{
		label: locale.promoActiveSpecificDay,
		value: 'specificDay',
	},
];

const dayOptions = Object.values(locale.dayOptions);

const TRANSITION_STYLES = {
	entering: {maxHeight: 100},
	entered:  {maxHeight: 100},
	exiting:  {maxHeight: 0},
	exited:  {maxHeight: 0},
};

const TRANSITION_DURATION = 300;

const MAX_PROMO_PERIOD_DAYS = 62;
const UNLIMITED_COUNT = 0;

const PromoForm = ({promoType, setPromoType, isEditing, showRejectModal, handleApprove, loadingApprove}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const action = location.state?.action;
	const viewOnly = location.state?.viewOnly;
	const rawCardData = location.state?.rawCardData;

	const submitWatcherRef = useRef(null); // Used to prevent double inject

	const isEdit = action === 'detail' ? true : false;
	const isReview = action === 'detail' ? true : false;

	const [selectedActiveDaysType, setSelectedActiveDayType] = useState(null);
	const [checkedActiveDays, setCheckedActiveDays] = useState([]);

	const [selectedActiveHours, setSelectedActiveHours] = useState(null);
	const [selectedActiveHoursTime, setSelectedActiveHoursTime] = useState(null);

	const [selectedMaxUsageType, setSelectedMaxUsageType] = useState(null);
	const [maxUsageCount, setMaxUsageCount] = useState(null);

	const [selectedMaxUsagePerUserType, setSelectedMaxUsagePerUserType] = useState(null);
	const [maxUsagePerUserCount, setMaxUsagePerUserCount] = useState(null);

	const [selectedDiscountType, setSelectedDiscountType] = useState(null);
	const [discountAmount, setDiscountAmount] = useState(null);

	const [selectedMenus, setSelectedMenus] = useState([]);
	const [selectedFlashSaleMenu, setSelectedFlashSaleMenu] = useState(null);

	const [platformList, setPlatformList] = useState([]);
	const [outletList, setOutletList] = useState([]);
	const [menuFromMenuTemplate, setMenuFromMenuTemplate] = useState([]);

	const [selectedMenuTemplate, setSelectedMenuTemplate] = useState(null);
	const [selectedSyncRequestId, setSelectedSyncRequestId] = useState(null);
	const [selectedPlatformID, setSelectedPlatformID] = useState(null);
	const [promoLabel, setPromoLabel] = useState('');
	const [selectedPromoPeriod, setSelectedPromoPeriod] = useState(null);
	const [selectedMerchantId, setSelectedMerchantId] = useState([]);
	const [promoTags, setPromoTags] = useState([]);

	const [errorMenuIndex, setErrorMenuIndex] = useState([]);
	const [gojekDiscountErrorIndex, setGojekDiscountErrorIndex] = useState([]);

	const [menuTemplateModalVisible, setMenuTemplateModalVisible] = useState(false);
	const [approvalIdModalVisible, setApprovalIdModalVisible] = useState(false);
	const [promoTemplateModalVisible, setPromoTemplateModalVisible] = useState(false);

	const activeHoursSettingVisible = !isFlashSalePromo && isGrab;

	const [scheduleModalConfig, setScheduleModalConfig] = useState({
		visible: false,
		payload: {},
		scheduleType: null,
	});

	const [schedules, setSchedules] = useState({
		regular: {},
		flash: {},
	});

	const [scheduleConflict, setScheduleConflict] = useState(null);

	const [promoId, setPromoId] = useState(null);

	const [tempDates, setTempDates] = useState([]);

	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [loadingRefresh, setLoadingRefresh] = useState(false);

	const startHourWatcher = useRef(null);
	const startMinuteWatcher = useRef(null);

	const isFlashSalePromo = promoType === PROMO_TYPE.FLASH_SALE;
	const isSingleDiscountPromo = promoType === PROMO_TYPE.SINGLE ? true : false;

	const isGrab = selectedPlatformID === platformList.find(el => el.label === 'Grab')?.id;
	const isGojek = selectedPlatformID === platformList.find(el => el.label === 'Gojek')?.id;

	const initialFetch = async () => {
		try {
			const response = await getPlatformList();
			if (response?.data?.rows?.length) {
				const platforms = response.data.rows;
				setPlatformList(platforms);

				/**
				 * Flash sale promo only available for Grab therefore force sales channel to Grab only
				 */
				if (isFlashSalePromo) setSelectedPlatformID(platforms.find(platform => platform.label === 'Grab')?.id);
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const calculateDiscountedPrice = (menuPrice, menuDiscountAmount = discountAmount) => {
		let output;
		output = selectedDiscountType === 'percentage'
			? menuPrice * (1 - menuDiscountAmount/100)
			: menuPrice - menuDiscountAmount;
		return output.toFixed();
	};

	const handleRemoveMenu = menuIndex => {
		const tempMenuArray = [...selectedMenus].filter((el, index) => index !== menuIndex);
		const tempErrorArray = [...errorMenuIndex].filter(el => el !== menuIndex);
		const tempGojekErrorArray = [...gojekDiscountErrorIndex].filter(el => el !== menuIndex);
		setSelectedMenus(tempMenuArray);
		setErrorMenuIndex(tempErrorArray);
		setGojekDiscountErrorIndex(tempGojekErrorArray);
	};

	const fieldDisabledController = isEdit && !isEditing ? true : false;

	const handlePostRequest = async payload => {
		try {
			const fetchFn = isFlashSalePromo ? createNewFlashSalePromo : createNewPromo;
			const response = await fetchFn(payload);
			if (response.success) {
				navigate('/promo/promo-request/price-cut');
				notification.open({
					message: locale.Notification.Success.message,
					description: locale.Notification.Success.description,
					type: 'success',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const handleEditRequest = async payload => {
		try {
			const response = await editPromo(payload);
			if (response.success) {
				navigate('/promo/promo-request/price-cut');
				notification.open({
					message: locale.Notification.EditSuccess.message,
					description: locale.Notification.EditSuccess.description,
					type: 'success',
				});
			} throw {};
		} catch (error) {
			handleErrorFetch(error);
		}
	};

	function range(start, end) {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	}

	const getRegularPromoPayload = () => {
		const payload = {
			label: promoLabel,
			menuTemplateId: selectedMenuTemplate?.id || null,
			platformId: selectedPlatformID,
			merchantId: selectedMerchantId.filter(selectedMerchant => outletList.some(outlet => outlet.id === selectedMerchant)), // To filter exist merchant
			tags: promoTags,
			startDate: selectedPromoPeriod?.startDate || null,
			endDate: selectedPromoPeriod?.endDate || null,
			days: selectedActiveDaysType === 'everyDay'
				? Array.from(Array(7).keys())
				: checkedActiveDays,
			maxQuotas: {
				total: selectedMaxUsageType === 'limited' ? Number(maxUsageCount) : 0,
				user: selectedMaxUsagePerUserType === 'limited' ? Number(maxUsagePerUserCount) : 0,
			},
			activeHours: {
				from: !activeHoursSettingVisible || selectedActiveHours === 'allDay'
					? '00:00'
					: moment(selectedActiveHoursTime?.start)?.format('HH:mm'),
				to: !activeHoursSettingVisible || selectedActiveHours === 'allDay'
					? '23:59'
					: moment(selectedActiveHoursTime?.end)?.format('HH:mm'),
			},
			templateType: promoType,
		};

		if (selectedSyncRequestId) payload.syncRequestId = selectedSyncRequestId;

		if (isSingleDiscountPromo) {
			payload.campaigns = [
				{
					menus: selectedMenus.map(menu => {
						return {
							menuId: menu.menuId,
							menuLabel: menu.menuLabel,
							menuPrice: menu.menuPrice,
							discountPrice: Number(calculateDiscountedPrice(menu.menuPrice)),
						};
					}).filter(mappedMenu => {
						if (mappedMenu.menuId) {
							return mappedMenu;
						}
					}),
					details: {
						type: selectedDiscountType,
						value: Number(discountAmount),
					},
				},
			];
		} else {
			payload.campaigns = selectedMenus.filter(id => id).map(menu => {
				return {
					menus: [{
						menuId: menu.menuId,
						menuLabel: menu.menuLabel,
						menuPrice: menu.menuPrice,
						discountPrice: Number(calculateDiscountedPrice(menu.menuPrice, menu.discountAmount)),
					}],
					details: {
						type: selectedDiscountType,
						value: Number(menu.discountAmount),
					},
				};
			});
		}

		return payload;
	};

	const getFlashSalePromoPayload = () => {
		return {
			label: promoLabel,
			menuTemplateId: selectedMenuTemplate?.id || null,
			platformId: selectedPlatformID,
			merchantId: selectedMerchantId.filter(selectedMerchant => outletList.some(outlet => outlet.id === selectedMerchant)), // To filter exist merchant
			startDate: selectedPromoPeriod?.startDate?.startOf('day') || null,
			endDate: selectedPromoPeriod?.endDate?.endOf('day') || null,
			promoDetails: {
				regular: {
					menu: {
						menuId: selectedFlashSaleMenu.menuId,
						menuLabel: selectedFlashSaleMenu.menuLabel,
						menuPrice: selectedFlashSaleMenu.menuPrice,
						discountPrice: Number(calculateDiscountedPrice(selectedFlashSaleMenu.menuPrice, schedules.regular.discountAmount)),
					},
					days: schedules.regular.checkedActiveDays,
					maxQuotas: {
						total: 0,
						user: schedules.regular.maxUsagePerUserCount,
					},
					activeHours: {
						from: schedules.regular.selectedActiveHoursTime[0],
						to: schedules.regular.selectedActiveHoursTime[1],
					},
					details: {
						type: selectedDiscountType,
						value: schedules.regular.discountAmount,
					},
				},
				flash: {
					menu: {
						menuId: selectedFlashSaleMenu.menuId,
						menuLabel: selectedFlashSaleMenu.menuLabel,
						menuPrice: selectedFlashSaleMenu.menuPrice,
						discountPrice: Number(calculateDiscountedPrice(selectedFlashSaleMenu.menuPrice, schedules.flash.discountAmount)),
					},
					days: schedules.flash.checkedActiveDays,
					maxQuotas: {
						total: 0,
						user: schedules.flash.maxUsagePerUserCount,
					},
					activeHours: {
						from: schedules.flash.selectedActiveHoursTime[0],
						to: schedules.flash.selectedActiveHoursTime[1],
					},
					details: {
						type: selectedDiscountType,
						value: schedules.flash.discountAmount,
					},
				},
			},
		};
	};

	const submit = async () => {
		try {
			if (submitWatcherRef.current) return;
			submitWatcherRef.current = true;
			setLoadingSubmit(true);
			const payload = isFlashSalePromo ? getFlashSalePromoPayload() : getRegularPromoPayload();

			if (selectedSyncRequestId) payload.syncRequestId = selectedSyncRequestId;

			if (isEdit) {
				payload.requestId = promoId;
				await handleEditRequest(payload);
			} else {
				await handlePostRequest(payload);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			submitWatcherRef.current = false;
			setLoadingSubmit(false);
		}
	};

	const filterMenuTemplate = () => {
		if (menuFromMenuTemplate?.menus?.length) {
			const output = menuFromMenuTemplate.menus.filter(item1 => !selectedMenus.some(item2 => item2.menuId === item1.menuId));
			return output;
		} else {
			return [];
		}
	};

	const convertCampaignDetailsToSchedule = campaignDetails => {
		return {
			regular: {
				checkedActiveDays: campaignDetails?.regular?.days,
				discountAmount: campaignDetails?.regular?.details?.value,
				maxUsagePerUserCount: campaignDetails?.regular?.maxQuotas?.user,
				selectedActiveHoursTime: [campaignDetails?.regular?.activeHours?.from, campaignDetails?.regular?.activeHours?.to ],
			},
			flash: {
				checkedActiveDays: campaignDetails?.flash?.days,
				discountAmount: campaignDetails?.flash?.details?.value,
				maxUsagePerUserCount: campaignDetails?.flash?.maxQuotas?.user,
				selectedActiveHoursTime: [campaignDetails?.flash?.activeHours?.from, campaignDetails?.flash?.activeHours?.to ],
			},
		};
	};

	const populateFormField = async dataTemplate => {
		const {
			menuTemplate,
			salesChannelId,
			promoPeriod,
			promoActiveDays,
			merchants,
			tags,
			promoMaxUsage,
			promoMaxUsagePerUser,
			rawMenus,
			label,
			templateType,
			syncRequestId,
			promoActiveHours,
			campaignDetails,
		} = dataTemplate;

		// Universal Field
		setSelectedMenuTemplate(menuTemplate);
		setSelectedPlatformID(salesChannelId);

		if (isEdit) {
			setSelectedPromoPeriod(promoPeriod);
			setSelectedSyncRequestId(syncRequestId);
			setPromoLabel(label);
		}

		setSelectedMerchantId(merchants);

		const promoDiscountTypeEntry = isFlashSalePromo ? campaignDetails?.flash : rawMenus?.[0];
		setSelectedDiscountType(promoDiscountTypeEntry?.details?.type || null);

		const menuDetails = await getMenuDetailsFromMenuTemplate(menuTemplate?.id);
		setMenuFromMenuTemplate(menuDetails.data);

		if (isFlashSalePromo) {
			// Flash Sale Field
			const tempFlashSaleMenu = {
				menuId: campaignDetails?.flash?.menu?.menuId,
				menuLabel: campaignDetails?.flash?.menu?.menuLabel,
				menuPrice: campaignDetails?.flash?.menu?.menuPrice,
			};
			setSelectedFlashSaleMenu(tempFlashSaleMenu);

			setSchedules(convertCampaignDetailsToSchedule(campaignDetails));
		} else {
			// Single & Multiple Promo Field
			setSelectedActiveDayType('specificDay');
			setCheckedActiveDays(promoActiveDays);

			setSelectedActiveHours('specificTime');
			setSelectedActiveHoursTime({
				start: promoActiveHours?.start,
				end: promoActiveHours?.end,
			});

			setPromoTags(tags);

			if (promoMaxUsage === UNLIMITED_COUNT) setSelectedMaxUsageType('unlimited');
			else {
				setSelectedMaxUsageType('limited');
				setMaxUsageCount(promoMaxUsage);
			}

			if (promoMaxUsagePerUser === UNLIMITED_COUNT) setSelectedMaxUsagePerUserType('unlimited');
			else {
				setSelectedMaxUsagePerUserType('limited');
				setMaxUsagePerUserCount(promoMaxUsagePerUser);
			}

			const isSingle = templateType === PROMO_TYPE.MULTIPLE ? false : true;
			let selectedMenusTemp = [];
			if (isSingle) {
				const promoDiscountAmount = rawMenus?.[0]?.details?.value || null;
				setDiscountAmount(promoDiscountAmount);

				selectedMenusTemp = menuDetails.data.menus.filter(item => rawMenus?.[0]?.menus.some(menu => menu.menuId === item.menuId));

			} else {
				const listOfMenuId = rawMenus.map(item => {
					return {
						menuId: item?.menus?.[0]?.menuId,
						discountAmount: item?.details?.value,
					};
				});

				selectedMenusTemp = listOfMenuId.map(el => {
					const validMenu = menuDetails.data.menus.find(menu => menu.menuId === el.menuId);
					return {
						...validMenu,
						...el,
						error: null,
					};
				});
			}

			setSelectedMenus(selectedMenusTemp);
		}
	};

	const validateNumberInput = (e, setterFn, {allowDecimal = false}) => {
		const inputtedValue = e.target.value;
		const regexPattern = allowDecimal ? /^[0-9]*\.?[0-9]*$/ : /^(|[1-9]\d*)$/;

		if (regexPattern.test(inputtedValue)) {
			setterFn(inputtedValue);
		}
	};

	const handleSelectApprovalId = item => {
		setSelectedSyncRequestId(item.id);
		setSelectedPlatformID(item?.platformId);
		setSelectedMerchantId(item?.merchantId);
		setApprovalIdModalVisible(false);
	};

	const openScheduleModal = scheduleType => {
		setScheduleModalConfig({
			visible: true,
			payload: schedules[scheduleType],
			scheduleType,
		});
	};

	const closeScheduleModal = () => {
		setScheduleModalConfig({
			visible: false,
			payload: {},
			scheduleType: null,
		});
	};

	const handleDeleteSchedule = scheduleType => {
		setSchedules(state => {
			const tempSchedule = cloneDeep(state);
			tempSchedule[scheduleType] = {};
			return tempSchedule;
		});
	};

	const refreshMenuTemplate = async () => {
		try {
			setLoadingRefresh(true);
			const response = await getMenuDetailsFromMenuTemplate(selectedMenuTemplate?.id);
			if (response.success && !isEmpty(response.data)) {
				setMenuFromMenuTemplate(response.data);
				notification.success({
					message: locale.refreshMappingPriceSuccess.message,
					description: locale.refreshMappingPriceSuccess.description,
				});
			}
		} catch (error) {
			handleErrorFetch(error, {
				message: locale.refreshMappingPriceFailed.message,
				description: locale.refreshMappingPriceFailed.description,
			});
		} finally {
			setLoadingRefresh(false);
		}
	};

	useEffect(() => {
		initialFetch();
		if (isEdit) {
			populateFormField(promoRequestPayloadConverter(rawCardData));
			setPromoId(rawCardData.id);
		}
	}, []);

	useEffect(async () => {
		if (Number(selectedPlatformID)) {
			const response = await getOutletList(selectedPlatformID);
			if (response?.data?.rows?.length) {
				const mappedResult = response.data.rows.map(e => ({
					...e,
					key: e.id,
				}));
				setOutletList(mappedResult);
			}
		}
	}, [selectedPlatformID]);

	useEffect(() => {
		if (!selectedMenus.length) return;
		let tempErrorIndex = [...errorMenuIndex];
		let tempGojekDiscountErrorIndex = [...gojekDiscountErrorIndex];
		selectedMenus.forEach((menu, index) => {

			const discountedPrice = isSingleDiscountPromo
				? calculateDiscountedPrice(menu.menuPrice)
				: calculateDiscountedPrice(menu.menuPrice, Number(menu.discountAmount));

			if (discountedPrice <= 0) {
				if (!tempErrorIndex.includes(index)) {
					tempErrorIndex.push(index);
					setErrorMenuIndex(tempErrorIndex);
				}
			} else {
				if (tempErrorIndex.includes(index)) {
					tempErrorIndex = tempErrorIndex.filter(el => el !== index);
					setErrorMenuIndex(tempErrorIndex);
				}
			}

			if (discountedPrice > (menu.menuPrice * 0.90)) {
				if (!tempGojekDiscountErrorIndex.includes(index)) {
					tempGojekDiscountErrorIndex.push(index);
					setGojekDiscountErrorIndex(tempGojekDiscountErrorIndex);
				}
			} else {
				if (tempGojekDiscountErrorIndex.includes(index)) {
					tempGojekDiscountErrorIndex = tempGojekDiscountErrorIndex.filter(el => el !== index);
					setGojekDiscountErrorIndex(tempGojekDiscountErrorIndex);
				}
			}

		});
	}, [discountAmount, selectedDiscountType, selectedMenus]);

	useEffect(() => {
		if (!isFlashSalePromo) return;
		const scheduleIsComplete = !isEmpty(schedules.regular) && !isEmpty(schedules.flash);

		// When user has set both schedules, check if flash and regular schedule are 2 hours apart
		if (!scheduleIsComplete) return;
		const promoInSameDayExist = schedules.regular.checkedActiveDays.filter(day => schedules.flash.checkedActiveDays.includes(day));

		if (promoInSameDayExist.length) {
			const normalPromoStartHour = moment(schedules.regular.selectedActiveHoursTime[0], 'HH:mm');
			const flashSaleStartHour = moment(schedules.flash.selectedActiveHoursTime[0], 'HH:mm');

			const hoursDiff = flashSaleStartHour.diff(normalPromoStartHour, 'hour');

			if (hoursDiff >= 2) {
				setScheduleConflict(false);
			} else {
				setScheduleConflict(true);
			}
		} else {
			setScheduleConflict(false);
		}
	}, [schedules]);

	return (
		<Card
			title={(
				<div className='flex flex-col'>
					<Title
						className='font-bold'
						level={5}
					>{
							isFlashSalePromo
								? discountTypeLocale.FlashSale.title
								: isSingleDiscountPromo
									? discountTypeLocale.SingleDiscount.title
									: discountTypeLocale.MultipleDiscount.title
						}</Title>
					<Text
						className='text-sm font-normal'
						type="secondary"
					>{locale.description}</Text>
				</div>
			)}
			extra={!viewOnly && (
				<a
					className='text-antd-blue-6'
					onClick={() => confirm({
						title: locale.ChangePromoTypeModal.title,
						content: locale.ChangePromoTypeModal.description,
						okText: locale.ChangePromoTypeModal.confirm,
						okButtonProps: {danger:  true},
						maskClosable: true,
						centered: true,
						onOk() {
							setPromoType(null);
						},
					})}
				>{locale.extra}</a>
			)}
		>
			<div>
				<MenuTemplateModal
					close={() => setMenuTemplateModalVisible(false)}
					visible={menuTemplateModalVisible}
					setMenuTemplate={menuTemplate => setSelectedMenuTemplate(menuTemplate)}
					menuTemplateSelected={isEmpty(selectedMenuTemplate) ? false : true}
					setMenuFromMenuTemplate={setMenuFromMenuTemplate}
					selectedMenus={selectedMenus}
					setSelectedMenus={setSelectedMenus}
				/>
				<PromoTemplateModal
					close={() => setPromoTemplateModalVisible(false)}
					visible={promoTemplateModalVisible}
					populateFormField={populateFormField}
					promoType={promoType}
				/>
				<ApprovalIdModal
					visible={approvalIdModalVisible}
					onClose={() => setApprovalIdModalVisible(false)}
					handleSelectItem={handleSelectApprovalId}
					localeConfig={{
						modalTitle: locale.ApprovalIdModal.modalTitle,
						searchPlaceholder: locale.ApprovalIdModal.searchPlaceholder,
						selectTableData: locale.ApprovalIdModal.selectTableData,
					}}
				/>
				<ScheduleModal
					visible={scheduleModalConfig.visible}
					payload={scheduleModalConfig.payload}
					scheduleType={scheduleModalConfig.scheduleType}
					promoConfig={{
						selectedFlashSaleMenu,
						selectedDiscountType,
					}}
					setSchedules={setSchedules}
					close={closeScheduleModal}
					fieldDisabledController={fieldDisabledController}
				/>
				<div className='flex flex-col gap-6'>

					{/* Select Promo Template */}
					{
						!viewOnly && (
							<div className='flex'>
								<div className='space-y-2 flex-1 flex flex-col gap-2'>
									<div>{locale.promoTemplateNameLabel}</div>
									<div className='flex'>
										<Button
											disabled={fieldDisabledController}
											className='flex-1'
											onClick={() => setPromoTemplateModalVisible(true)}
											type='primary'
										>{locale.promoTemplateNameButtonLabel}</Button>
										<div className='flex-1' />
									</div>
								</div>
								<div className='md:flex-1' />
							</div>
						)
					}
					{/* End of Select Promo Template */}

					{/* Select Menu Template */}
					<div className='flex'>
						<div className='space-y-2 flex-1 flex flex-col gap-2'>
							<div>{locale.templateNameLabel}</div>
							<div className='flex'>
								{
									isEmpty(selectedMenuTemplate)
										? (
											<>
												<Button
													disabled={fieldDisabledController}
													className='flex-1'
													onClick={() => setMenuTemplateModalVisible(true)}
													type='primary'
												>{locale.templateNameButtonLabel}</Button>
												<div className='flex-1' />
											</>
										)
										: (
											<div className='flex flex-col gap-2'>
												<div className='flex items-center gap-2 font-medium'>
													<FileDoneOutlined />
													<div>
														{selectedMenuTemplate.label}
													</div>
												</div>
												{
													!fieldDisabledController && (
														<div className='flex gap-2'>
															<a
																onClick={() => {
																	setMenuTemplateModalVisible(true);
																}}
															>{locale.change}</a>
															<a
																className='text-antd-red-5'
																onClick={() => {
																	// Remove menu template id, discount menu and menu list from the template
																	setSelectedMenuTemplate(null);
																	setSelectedMenus([]);
																	setMenuFromMenuTemplate([]);
																}}
															>{locale.remove}</a>
														</div>
													)
												}
											</div>
										)
								}
							</div>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Select Menu Template */}

					{/* Select Approval ID */}
					<div className='flex'>
						<div className='space-y-2 flex-1 flex flex-col gap-2'>
							<div>{
								!selectedSyncRequestId
									? locale.linkSyncRequestIdLabel
									: locale.linkSyncRequestIdLabelSelected
							}</div>
							<div className='flex'>
								{
									!selectedSyncRequestId
										? (
											<>
												<Button
													disabled={fieldDisabledController}
													className='flex-1'
													onClick={() => setApprovalIdModalVisible(true)}
													type='primary'
												>{locale.linkSyncRequestIdButtonLabel}</Button>
												<div className='flex-1' />
											</>
										)
										: (
											<div className='flex flex-col gap-2'>
												<div className='flex items-center gap-2 font-medium'>
													<FileDoneOutlined />
													<div>
														{selectedSyncRequestId}
													</div>
												</div>
												{
													!fieldDisabledController && (
														<div className='flex gap-2'>
															<a
																onClick={() => {
																	setApprovalIdModalVisible(true);
																}}
															>{locale.change}</a>
															<a
																className='text-antd-red-5'
																onClick={() => {
																	setSelectedSyncRequestId(null);
																}}
															>{locale.remove}</a>
														</div>
													)
												}
											</div>
										)
								}
							</div>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Select Approval ID */}

					{/* Select Channel */}
					<div className='flex'>
						<div className='space-y-2 flex-1 flex flex-col gap-2'>
							<div>{locale.salesChannelLabel}</div>
							<Select
								showSearch
								disabled={fieldDisabledController || isFlashSalePromo}
								value={selectedPlatformID}
								onChange={e => {
									setSelectedPlatformID(e);
									setSelectedMerchantId([]);
								}}
								placeholder={locale.salesChannelPlaceholder}
								optionFilterProp="children"
								className="w-full"
							>
								{platformList.map(platform => (
									<Radio
										key={platform.id}
										value={platform.id}
									>{platform.label}</Radio>
								))}
							</Select>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Select Channel */}

					{/* Promo Name Input */}
					<div className='flex'>
						<div className='space-y-2 flex-1 flex flex-col gap-2'>
							<div>{locale.promoNameLabel}</div>
							<Input
								disabled={fieldDisabledController}
								value={promoLabel}
								onChange={e => setPromoLabel(e.target.value)}
								placeholder={locale.promoNamePlaceholder}
								className="w-full"
							/>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Promo Name Input */}

					{/* Promo Period */}
					<div className='flex'>
						<div className='space-y-2 flex-1 flex flex-col gap-2'>
							<div>{locale.promoPeriodLabel}</div>
							<DatePicker.RangePicker
								showTime={isFlashSalePromo ? false : true}
								disabled={fieldDisabledController}
								value={[selectedPromoPeriod?.startDate, selectedPromoPeriod?.endDate]}
								onChange={value => setSelectedPromoPeriod({
									startDate: value?.[0],
									endDate: value?.[1],
								})}
								disabledDate={current => {
									const yesterday = new Date();
									yesterday.setDate(yesterday.getDate() - 1);
									if (!tempDates || tempDates.length === 0) {
										return current && current.valueOf() <= yesterday;
									} else {
										if (tempDates[1]) {
											const dayDiff = Math.abs(moment().diff(tempDates[1], 'days'));
											if (dayDiff >= MAX_PROMO_PERIOD_DAYS) {
												return tempDates[1].diff(current, 'days') > MAX_PROMO_PERIOD_DAYS;
											} else {
												return current.valueOf() <= yesterday;
											}
										} else {
											return current.diff(tempDates[0], 'days') > MAX_PROMO_PERIOD_DAYS;
										}
									}
								}}
								onCalendarChange={val => setTempDates(val)}
								onOpenChange={open => open && setTempDates([])}
								className="w-full"
							/>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Promo Period */}

					{/* Promo Active Day */}
					{
						!isFlashSalePromo && (
							<div className='flex'>
								<div className='space-y-2 flex-1 flex flex-col gap-2'>
									<div>{locale.promoActiveDayLabel}</div>
									<Radio.Group
										onChange={e => setSelectedActiveDayType(e.target.value)}
										disabled={fieldDisabledController}
										value={selectedActiveDaysType}
									>
										{activeDay.map(activeDay => (
											<Radio
												key={activeDay.value}
												value={activeDay.value}
											>{activeDay.label}</Radio>
										))}
									</Radio.Group>
									{
										selectedActiveDaysType === 'specificDay' && (
											<Checkbox.Group
												disabled={fieldDisabledController}
												options={dayOptions.map((day, index) => {
													return {
														label: day,
														value: index,
													};
												})}
												value={checkedActiveDays}
												onChange={checkedValues => setCheckedActiveDays(checkedValues)}
											/>
										)
									}
								</div>
							</div>
						)
					}
					{/* End of Promo Active Day */}

					{/* Promo Active Hour */}
					{
						activeHoursSettingVisible
						&& (
							<>
								<div className='flex'>
									<div className='space-y-2 flex-1 flex flex-col gap-2'>
										<div>{locale.promoActiveHourLabel}</div>
										<Radio.Group
											onChange={e => setSelectedActiveHours(e.target.value)}
											value={selectedActiveHours}
											disabled={fieldDisabledController}
										>
											{activeHours.map(activeHour => (
												<Radio
													key={activeHour.value}
													value={activeHour.value}
												>{activeHour.label}</Radio>
											))}
										</Radio.Group>
										{
											selectedActiveHours === 'specificTime' && (
												<TimePicker.RangePicker
													disabledTime={(_, type) => {
														return {
															disabledHours: () => range(0, 24)
																.splice(
																	type === 'start' ? 22 : 0,
																	(startHourWatcher.current || 0) + 2,
																),
															disabledMinutes: () => type === 'start'
																? null
																: range(0, 60)
																	.splice(
																		0,
																		startMinuteWatcher.current || 0,
																	),
															disabledSeconds: (selectedHour, selectedMinute) => {
																if (type === 'start') {
																	startHourWatcher.current = selectedHour;
																	startMinuteWatcher.current = selectedMinute;
																}
															},
														};
													}}
													value={[selectedActiveHoursTime?.start, selectedActiveHoursTime?.end]}
													disabled={fieldDisabledController}
													onChange={value => {
														if (value === null) {
															setSelectedActiveHoursTime(null);
															startHourWatcher.current = null;
															startMinuteWatcher.current = null;
														} else {
															if (value?.[0] && value?.[1]) {
																const hoursDiff = value[1].diff(value[0], 'hour');

																if (hoursDiff.toFixed() >= 2) {
																	setSelectedActiveHoursTime({
																		start: value?.[0],
																		end: value?.[1],
																	});
																}
															}
														}
													}}
													format={'HH:mm'}
													className="w-full"
												/>
											)
										}
									</div>
									<div className='md:flex-1' />
								</div>
							</>
						)
					}
					{/* End of Promo Active Hour */}


					{/* Outlet Select Section */}
					<div className='w-full flex flex-col gap-2'>
						<div>{locale.selectOutletLabel}</div>
						<Transfer
							disabled={fieldDisabledController}
							titles={[locale.allOutlet, locale.selectedOutlet]}
							listStyle={{width: '100%', height: 350}}
							dataSource={outletList}
							render={item => transferOutletFormatter(item)}
							targetKeys={selectedMerchantId}
							onChange={(selectedKeys, direction) => {
								let tempArray = [...selectedMerchantId];
								if (direction === 'right') {
									selectedKeys.forEach(merchantId => {
										if (!tempArray.includes(merchantId)) {
											tempArray.push(merchantId);
										}
									});
								} else {
									tempArray = selectedKeys;
								}
								setSelectedMerchantId(tempArray);
							}}
							showSearch
							filterOption={(input, option) => {
								const labelFormat = transferOutletFormatter(option);
								return labelFormat.toLocaleLowerCase().indexOf(input.toLowerCase()) >= 0;
							}
							}
						/>
					</div>
					{/* End of Outlet Select Section */}

					{/* Tags Section */}
					{
						!isFlashSalePromo && (
							<div className='flex'>
								<div className='space-y-2 flex-1 flex flex-col gap-2'>
									<div>{locale.tagsLabel}</div>
									<Select
										disabled={fieldDisabledController}
										placeholder={locale.tagsPlaceholder}
										open={false}
										mode='tags'
										value={promoTags}
										onChange={value => setPromoTags(value)}
										tokenSeparators={[',']}
									/>
								</div>
								<div className='md:flex-1' />
							</div>
						)
					}
					{/* End of Tags Section */}

					{/* Discount Type Section */}
					<div className='flex'>
						<div className='space-y-2 flex-1 flex flex-col gap-2'>
							<div>{locale.discountTypeLabel}</div>
							<Select
								disabled={fieldDisabledController}
								value={selectedDiscountType}
								onChange={value => setSelectedDiscountType(value)}
								placeholder={locale.discountTypePlaceholder}
							>
								<Option
									key={'percentage'}
									value={'percentage'}
								>{locale.percentageDiscountType}</Option>
								<Option
									key={'net'}
									value={'net'}
								>{locale.nettDiscountType}</Option>
							</Select>
						</div>
						<div className='md:flex-1' />
					</div>
					{/* End of Discount Type Section */}

					{/* Discount Amount Section */}
					{
						isSingleDiscountPromo && (
							<div className='flex gap-2 flex-col md:flex-row'>
								<div className='space-y-2 flex-1 flex flex-col gap-2'>
									<div>{locale.discountAmountLabel}</div>
									<div>
										<Input
											value={discountAmount}
											onChange={e => validateNumberInput(e, setDiscountAmount, {allowDecimal: true})}
											placeholder={locale.discountAmountPlaceholder}
											addonAfter={selectedDiscountType === 'percentage' && <PercentageOutlined />}
											addonBefore={selectedDiscountType === 'net' && 'Rp'}
											disabled={fieldDisabledController || !selectedDiscountType}
										/>
										<Transition
											in={isGojek && discountAmount && gojekDiscountErrorIndex.length}
											timeout={TRANSITION_DURATION}>
											{state => (
												<div style={{
													transition: `max-height ${TRANSITION_DURATION}ms ease-in-out`,
													maxHeight: 0,
													overflow: 'hidden',
													...TRANSITION_STYLES[state],
												}}>
													<Text
														className='transition-all duration-1000'
														type='danger'
													>{locale.SelectedMenu.invalidDiscountAmountAlert}</Text>
												</div>
											)}
										</Transition>
									</div>
								</div>
								<div className='md:flex-1' />
							</div>
						)
					}
					{/* End of Discount Amount Section */}

					{/* Max Usage Section */}
					{
						!isFlashSalePromo && (
							<div className='flex'>
								<div className='space-y-2 flex-1 flex flex-col gap-2'>
									<div>{locale.maxUsageLabel}</div>
									<Radio.Group
										disabled={fieldDisabledController}
										onChange={e => setSelectedMaxUsageType(e.target.value)}
										value={selectedMaxUsageType}
									>
										{maxUsageConfig.map(option => (
											<Radio
												key={option.value}
												value={option.value}
											>{option.label}</Radio>
										))}
									</Radio.Group>
									{
										selectedMaxUsageType === 'limited' && (
											<Input
												disabled={fieldDisabledController}
												placeholder={locale.maxUsagePlaceholder}
												value={maxUsageCount}
												onChange={e => validateNumberInput(e, setMaxUsageCount, {allowDecimal: false})}
											/>
										)
									}
								</div>
								<div className='md:flex-1' />
							</div>
						)
					}
					{/* End of Max Usage Section */}

					{/* Max Usage Per User Section */}
					{
						!isFlashSalePromo && (
							<div className='flex'>
								<div className='space-y-2 flex-1 flex flex-col gap-2'>
									<div>{locale.maxUsagePerUserLabel}</div>
									<Radio.Group
										disabled={fieldDisabledController}
										onChange={e => setSelectedMaxUsagePerUserType(e.target.value)}
										value={selectedMaxUsagePerUserType}
									>
										{maxUsageConfig.map(option => (
											<Radio
												key={option.value}
												value={option.value}
											>{option.label}</Radio>
										))}
									</Radio.Group>
									{
										selectedMaxUsagePerUserType === 'limited' && (
											<Input
												disabled={fieldDisabledController}
												placeholder={locale.maxUsagePerUserPlaceholder}
												value={maxUsagePerUserCount}
												onChange={e => validateNumberInput(e, setMaxUsagePerUserCount, {allowDecimal: false})}
											/>
										)
									}
								</div>
								<div className='md:flex-1' />
							</div>
						)
					}
					{/* End of Max Usage Per User Section */}

					{/* Discounted Menu Section (Single & Multiple) */}
					{
						!isFlashSalePromo && (
							<div className='flex flex-col'>
								{
									selectedMenus.map((menu, index) => (
										<div
											className={`pb-2 gap-6 grid grid-cols-2 ${isSingleDiscountPromo ? 'xl:grid-cols-5' : 'xl:grid-cols-6'}`}
											key={index}>
											{/* Menu Name */}
											<div className='flex-1 flex flex-col gap-2 discounted__menu__name'>
												<div>{locale.SelectedMenu.name.replace('{{order}}', index + 1)}</div>
												<Select
													showSearch
													disabled={fieldDisabledController}
													className='w-full'
													value={ menu?.menuId ? `${menu.menuLabel} - [${(menu?.menuTags?.join?.(', '))?.toUpperCase?.()}]` : ''}
													onChange={value => {
														const selectedMenu = filterMenuTemplate().find(el => el.menuId === value);
														const tempArray = [...selectedMenus];
														tempArray[index] = {
															...selectedMenu,
															error: null,
														};
														if (!isSingleDiscountPromo) {
															tempArray[index].discountAmount = null;
														}
														setSelectedMenus(tempArray);
													}}
													filterOption={(input, option) => {
														const childContent = option?.children?.props?.children || '';
														return childContent.toLowerCase().includes(input.toLowerCase());
													}
													}
												>
													{
														filterMenuTemplate().map(menu => (
															<Option
																key={menu.menuId}
																value={menu.menuId}
															>
																<Text
																	ellipsis={{tooltip: `${menu.menuLabel} - [${(menu?.menuTags?.join?.(', '))?.toUpperCase?.()}]`}}
																>
																	{`${menu.menuLabel} - [${(menu?.menuTags?.join?.(', '))?.toUpperCase?.()}]`}
																</Text>
															</Option>
														))
													}
												</Select>
											</div>
											{/* End of Menu Name */}

											{/* Original Price */}
											<div className='flex-1 flex flex-col gap-2'>
												<div>{locale.SelectedMenu.normalPrice.replace('{{order}}', index + 1)}</div>
												<Input
													addonBefore='Rp'
													value={moneyFormat({value: selectedMenus[index].menuPrice || 0, includeCurrency: false})}
													disabled
												/>
											</div>
											{/* End of Original Price */}

											{/* Discount Amount */}
											{
												!isSingleDiscountPromo && (
													<div className='flex-1 flex flex-col gap-2'>
														<div>{locale.SelectedMenu.discountAmount.replace('{{order}}', index + 1)}</div>
														<div>
															<Input
																addonAfter={selectedDiscountType === 'percentage' && <PercentageOutlined />}
																addonBefore={selectedDiscountType === 'net' && 'Rp'}
																disabled={fieldDisabledController || !selectedDiscountType}
																onChange={e => {
																	const setterFn = () => {
																		const tempArray = [...selectedMenus];
																		tempArray[index].discountAmount = e.target.value;
																		setSelectedMenus(tempArray);
																	};

																	validateNumberInput(e, setterFn, {allowDecimal: true});
																}}
																value={selectedMenus[index].discountAmount || ''}
															/>
															{
																<Transition
																	in={isGojek && selectedMenus[index].discountAmount && gojekDiscountErrorIndex.includes(index)}
																	timeout={TRANSITION_DURATION}>
																	{state => (
																		<div style={{
																			transition: `max-height ${TRANSITION_DURATION}ms ease-in-out`,
																			maxHeight: 0,
																			overflow: 'hidden',
																			...TRANSITION_STYLES[state],
																		}}>
																			<Text
																				className='transition-all duration-1000'
																				type='danger'
																			>{locale.SelectedMenu.invalidDiscountAmountAlert}</Text>
																		</div>
																	)}
																</Transition>
															}
														</div>
													</div>
												)
											}
											{/* End of Discount Amount */}

											{/* Discounted Price */}
											<div className='flex-1 flex flex-col gap-2'>
												<div>{locale.SelectedMenu.discountedPrice.replace('{{order}}', index + 1)}</div>
												<div>
													<Input
														value={
															selectedMenus[index].menuPrice
																? isSingleDiscountPromo
																	? moneyFormat({value: calculateDiscountedPrice(selectedMenus[index].menuPrice)})
																	: moneyFormat({value: calculateDiscountedPrice(selectedMenus[index].menuPrice, selectedMenus[index].discountAmount)})
																: 0
														}
														disabled />
													<Transition
														in={errorMenuIndex.includes(index)}
														timeout={TRANSITION_DURATION}>
														{state => (
															<div style={{
																transition: `max-height ${TRANSITION_DURATION}ms ease-in-out`,
																maxHeight: 0,
																overflow: 'hidden',
																...TRANSITION_STYLES[state],
															}}>
																<Text
																	className='transition-all duration-1000'
																	type='danger'
																>{locale.lessThanZeroError}</Text>
															</div>
														)}
													</Transition>
												</div>
											</div>
											{/* End of Discounted Price */}

											{/* Discounted Price */}
											<div className='flex-1 flex flex-col gap-2'>
												<div>{locale.SelectedMenu.mappingPrice}</div>
												<div>
													<Input
														value={
															moneyFormat({
																value: (menuFromMenuTemplate?.menus?.find?.(menuOrigin => menuOrigin.menuId == selectedMenus[index]?.menuId))?.mappingPrice || 0,
															})
														}
														disabled />
												</div>
											</div>
											{/* End of Discounted Price */}

											{/* Delete Button */}
											<div className='mt-auto lg:mt-0'>
												<div
													className='mb-2 hidden lg:block'
													style={{height: 22}} />
												<Popconfirm
													disabled={fieldDisabledController}
													title={locale.DeleteMenuPopconfirm.title}
													onConfirm={() => handleRemoveMenu(index)}
													okText={locale.DeleteMenuPopconfirm.ok}
													cancelText={locale.DeleteMenuPopconfirm.no}
												>
													<Button
														danger
														disabled={fieldDisabledController}
													><DeleteOutlined /> {locale.deleteMenu}</Button>
												</Popconfirm>
											</div>
											{/* End of Delete Button */}

										</div>
									))
								}
								{
									(!fieldDisabledController && filterMenuTemplate().length)
										? (
											<div className='flex pt-4 gap-2'>
												<Button
													type="primary"
													ghost
													onClick={() => setSelectedMenus(selectedMenus.concat({
														menuId: null,
													}))}
												>{locale.addMenu}</Button>
												<Button
													type="primary"
													ghost
													loading={loadingRefresh}
													onClick={refreshMenuTemplate}
												>{locale.refreshMappingPrice}</Button>
											</div>
										)
										: null
								}
							</div>
						)
					}
					{/* End of Discounted Menu Section (Single & Multiple) */}

					{/* Discounted Menu Section (Flash Sale) */}
					{
						isFlashSalePromo && (
							<div className='flex'>
								<div className='space-y-2 flex-1 flex flex-col gap-2'>
									<div>{locale.selectedDiscountMenuLabel}</div>
									<Select
										showSearch
										disabled={!filterMenuTemplate().length}
										value={selectedFlashSaleMenu?.menuId ? selectedFlashSaleMenu?.menuLabel : null}
										onChange={value => {
											const selectedMenu = filterMenuTemplate().find(el => el.menuId === value);
											setSelectedFlashSaleMenu(selectedMenu);
										}}
										filterOption={(input, option) => {
											const childContent = option?.children?.props?.children || '';
											return childContent.toLowerCase().includes(input.toLowerCase());
										}}
										placeholder={locale.selectedDiscountMenuPlaceholder}
									>
										{
											filterMenuTemplate().map(menu => (
												<Option
													value={menu.menuId}
													key={menu.menuId}>
													<Text
														ellipsis={{
															tooltip: `${menu.menuLabel} - [${(menu?.menuTags?.join?.(', '))?.toUpperCase?.()}]`,
															rows: 1,
														}}
													>
														{`${menu.menuLabel} - [${(menu?.menuTags?.join?.(', '))?.toUpperCase?.()}]`}
													</Text>
												</Option>
											))
										}
									</Select>
								</div>
								<div className='md:flex-1' />
							</div>
						)
					}
					{/* End of Discounted Menu Section (Flash Sale) */}

					{/* Flash Sale Schedules Section */}
					{
						isFlashSalePromo && (
							<div className='flex'>
								<div className='space-y-2 flex-1 flex flex-col gap-2'>
									<div>
										<div>{locale.scheduleCycleLabel}</div>
										<Transition
											in={scheduleConflict}
											timeout={500}>
											{state => (
												<div style={{
													transition: `max-height ${500}ms ease-in-out`,
													maxHeight: 0,
													overflow: 'hidden',
													...TRANSITION_STYLES[state],
												}}>
													<Typography.Text
														className='transition-all duration-1000'
														type='danger'
													>{locale.scheduleActiveHourConflict}</Typography.Text>
												</div>
											)}
										</Transition>
									</div>
									<div className='grid grid-cols-3 gap-6'>
										{
											Object.keys(schedules).map(scheduleType => {
												const scheduleIsEmpty = isEmpty(schedules[scheduleType]);
												return (
													<div
														className='border border-antd-netural-5 rounded-md p-4 flex flex-col'
														key={scheduleType}>
														<div>
															<div>
																<Typography.Title level={5}>{
																	scheduleType === 'regular'
																		? locale.normalSchedule
																		: locale.flashSaleSchedule
																}</Typography.Title>
															</div>
															{
																scheduleIsEmpty
																	? (
																		<div>
																			<Typography.Text type='secondary'>{
																				locale.emptySchedulePlaceholder.replace(
																					'{{scheduleType}}',
																					(scheduleType === 'regular'
																						? locale.normalSchedule
																						: locale.flashSaleSchedule).toLowerCase(),
																				)
																			}</Typography.Text>
																		</div>
																	)
																	: (
																		<>
																			<div>
																				<Typography.Text type='secondary'>{schedules[scheduleType].checkedActiveDays.map(day => dayOptions[day]).join(', ')}</Typography.Text>
																			</div>
																			<div>
																				<Typography.Text type='secondary'>{schedules[scheduleType].selectedActiveHoursTime?.[0]} - {schedules[scheduleType].selectedActiveHoursTime?.[1]}</Typography.Text>
																			</div>
																		</>
																	)
															}
														</div>
														<div className='flex justify-end gap-2 mt-auto pt-4'>
															<Button
																disabled={!selectedFlashSaleMenu || !selectedDiscountType}
																onClick={() => openScheduleModal(scheduleType)}
																size='small'>
																{
																	fieldDisabledController
																		? locale.viewSchedule
																		: scheduleIsEmpty ? locale.scheduleCycleAction : locale.edit
																}
															</Button>
															{
																fieldDisabledController || scheduleIsEmpty
																	? null
																	: (
																		<Popconfirm
																			title={locale.deleteScheduleConfimation}
																			onConfirm={() => handleDeleteSchedule(scheduleType)}
																			okText={locale.yes}
																			cancelText={locale.no}
																		>
																			<Button
																				danger
																				size='small'>{locale.delete}</Button>
																		</Popconfirm>
																	)
															}
														</div>
													</div>
												);
											})
										}
									</div>
								</div>
							</div>
						)
					}
					{/* End of Flash Sale Schedules Section */}

					{/* Action Section */}
					{
						!viewOnly && (
							<div className='flex gap-2 justify-end'>
								<Popconfirm
									placement='topRight'
									title={locale.BackPopconfirm.title}
									onConfirm={() => navigate(-1)}
									okText={locale.BackPopconfirm.ok}
									cancelText={locale.BackPopconfirm.no}>
									<Button>{locale.cancelBtn}</Button>
								</Popconfirm>
								<Button
									loading={loadingSubmit || loadingApprove}
									disabled={
										fieldDisabledController ||
										// Universal Field (Used both in normal promo and flash sale)
										!promoLabel ||
										isEmpty(selectedMenuTemplate) ||
										isEmpty(selectedPromoPeriod) ||
										!selectedPlatformID ||
										!selectedMerchantId.length ||
										// Single & Multiple Discount Amount Field
										!isFlashSalePromo && (
											isSingleDiscountPromo && !discountAmount ||
											(!selectedActiveDaysType || selectedActiveDaysType === 'specificDay' && !checkedActiveDays?.length) ||
											(isGrab && !selectedActiveHours || selectedActiveHours === 'specificTime' && isEmpty(selectedActiveHoursTime)) ||
											!selectedMaxUsageType ||
											(selectedMaxUsageType === 'limited' && (!maxUsageCount || Number(maxUsageCount) <= 0)) ||
											!selectedMaxUsagePerUserType ||
											(selectedMaxUsagePerUserType === 'limited' && (!maxUsagePerUserCount || Number(maxUsagePerUserCount) <= 0)) ||
											!selectedMenus.filter(menu => menu.menuId !== null).length ||
											selectedMenus.filter(menu => menu.discountAmount == false).length ||
											isGojek && gojekDiscountErrorIndex.length ||
											errorMenuIndex.length
										) ||
										// Flash Sale Field
										isFlashSalePromo && (
											!selectedDiscountType ||
											!selectedFlashSaleMenu ||
											isEmpty(schedules['regular']) ||
											isEmpty(schedules['flash']) ||
											scheduleConflict === true
										)
									}
									onClick={() =>
										Modal.confirm({
											title: locale.CreateConfirmation.title,
											content: locale.CreateConfirmation.content,
											okText: locale.CreateConfirmation.approve,
											onOk: () => submit(),
											centered: true,
											maskClosable: true,
										})
									}
									type='primary'
								>{locale.submitBtn}</Button>
							</div>
						)
					}
					{/* End of Action Section */}

				</div>
				{
					(isReview && rawCardData.status === 'pending') ? (
						<ProtectedComponent
							when={u => u.roles.some(
								role => [USER_ROLES.SYNC_PROMO.ADMIN, USER_ROLES.SYNC_PROMO.APPROVER].includes(role),
							)}
						>
							<div className='flex gap-2 justify-end pt-4'>
								<Button
									danger
									onClick={() => showRejectModal()}
								>{locale.reject}</Button>
								<Button
									onClick={() =>
										confirm({
											title: locale.Modal.ApproveConfirmation.title,
											content: locale.Modal.ApproveConfirmation.content,
											okText: locale.Modal.ApproveConfirmation.approve,
											onOk: () => handleApprove(),
											centered: true,
											maskClosable: true,
										})
									}
									type='primary'>{locale.approve}</Button>
							</div>
						</ProtectedComponent>
					) : null
				}

			</div>
		</Card>
	);
};

PromoForm.defaultProps = {
	promoType: null,
	setPromoType: () => null,
	isEditing: false,
	showRejectModal: () => null,
	handleApprove: () => null,
	loadingApprove: false,
};

PromoForm.propTypes = {
	promoType: PropTypes.oneOf(Object.values(PROMO_TYPE)),
	setPromoType: PropTypes.func,
	isEditing: PropTypes.bool,
	showRejectModal: PropTypes.func,
	handleApprove: PropTypes.func,
	loadingApprove: PropTypes.bool,
};

export default PromoForm;