import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {isEmpty} from 'lodash';

import MenuTemplateDetailHeader from './MenuTemplateDetailHeader';
import MenuTemplateFormBody from '../MenuTemplateForm/MenuTemplateFormBody';

import MenuTemplateCart from 'components/MenuTemplateCart';

import {cartCompressHandler, menuTemplateBodyFormatter} from 'utils/utils';
import {STORAGE_KEY} from 'utils/constants';

const MenuTemplateDetail = () => {
	const navigate = useNavigate();
	const state = JSON.parse(sessionStorage.getItem(STORAGE_KEY.MENU_TEMPLATE_STATE) || {});
	const menuTemplateLabel = state?.menuTemplateState?.label;
	let menuTemplateObj = state?.menuTemplateState?.body;

	const [cart, setCart] = useState([]);

	menuTemplateObj = menuTemplateBodyFormatter(menuTemplateObj);

	const cartSetter = item => {
		const cartTemp = JSON.parse(JSON.stringify(cart));
		const compressedCart = cartCompressHandler(cartTemp.concat(item));
		setCart(compressedCart);
	};

	useEffect(() => {
		if (isEmpty(state) || Object.keys(state?.menuTemplateState).length === 0) navigate('/sales-channel/menu-template', {replace: true});
	}, []);

	return (
		<div className="bg-white flex-1 overflow-auto flex flex-col">
			<div className='flex flex-col flex-1'>
				<MenuTemplateDetailHeader
					menuTemplateLabel={menuTemplateLabel}
				/>
				<MenuTemplateFormBody
					menuTemplateLabel={menuTemplateLabel}
					menuTemplateObj={menuTemplateObj}
					viewOnly
					cart={cart}
					setCart={cartSetter}
				/>
			</div>
			<div className='flex justify-end px-6 pb-6 sticky bottom-0'>
				<MenuTemplateCart
					cart={cart}
					setCart={setCart}
				/>
			</div>
		</div>
	);
};

export default MenuTemplateDetail;
